import React from "react";
import clsx from "clsx";
export type ContainerVariant = keyof typeof ContainerVariants;
interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
  variant?: ContainerVariant;
}
export const Container = React.forwardRef<HTMLDivElement, Props>(({
  variant = "normal",
  className,
  children,
  ...props
}, ref) => {
  return <div ref={ref} {...props} className={clsx("relative mx-auto", ContainerVariants[variant], className)}>
        {children}
      </div>;
});
const ContainerVariants = {
  narrow: "max-w-2xl px-4 lg:px-0",
  indent: "max-w-7xl px-8",
  normal: "max-w-7xl px-4 lg:px-4",
  wide: "max-w-7xl px-4 lg:px-0",
  carousel: "max-w-7xl px-0 md:px-1"
};