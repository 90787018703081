import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder({
  dataset: "production",
  projectId: "7esgctdt",
});

export function imageUrlFor(source) {
  const url = source?.asset?.url;
  if (url) return url;
  if (!source) return "";
  return builder.image(source).url();
}

export function sanityImageSize(imageUrl: string): {
  width: number;
  height: number;
} {
  const match = /(\d+x\d+)\.\w+$/.exec(imageUrl);
  if (!match) {
    return {
      width: 0,
      height: 0,
    };
  }
  const dimensions = match[0];
  const [width, height] = dimensions.split("x").map((v) => parseInt(v, 10));

  return {
    width: width,
    height: height,
  };
}
