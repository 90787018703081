const websiteURL = () => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview") {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }
  return process.env.NEXT_PUBLIC_WWW_URL || "https://www.empathy.com";
};

export const absoluteURL = (path: string | undefined): string | undefined => {
  if (path === undefined) return undefined;

  const prefix = websiteURL();
  const url = new URL(prefix);
  url.pathname = path;
  return url.toString();
};
