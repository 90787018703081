import { toFieldPath, toObjectId } from "@stackbit/annotations";
import { NextSeo } from "next-seo";
import { absoluteURL } from "../../lib/absoluteURL";
import { getDocumentComponent } from "../../lib/sanity/sanity.util";
import type { SanityPage } from "../../lib/sanity/sanity.types";
import { PageLayout } from "../PageLayout";
import { PartnerProvider } from "../Layout/PartnerContext";
import { Section } from "../Harmony/components/Section";
import { Container } from "../Harmony/components/Container";
import { imageUrlFor } from "../../lib/sanity/sanity.image";
interface Props {
  page: SanityPage;
  preview?: boolean;
}
const isDev = process.env.NODE_ENV === "development";
export const SanityPageLayout = (props: Props) => {
  const {
    page,
    preview
  } = props;
  return <PartnerProvider partnerSlug={page.partnerSlug} data-sentry-element="PartnerProvider" data-sentry-component="SanityPageLayout" data-sentry-source-file="SanityPageLayout.tsx">
      <NextSeo title={page.seo?.metaTitle || page.title} description={page.seo?.metaDescription} nofollow={page.seo?.indexed === false} noindex={page.seo?.indexed === false} canonical={absoluteURL(page.slug?.current)} openGraph={{
      images: page.seo?.socialImage ? [{
        url: imageUrlFor(page.seo.socialImage),
        alt: page.seo.metaTitle || page.title
      }] : undefined
    }} data-sentry-element="NextSeo" data-sentry-source-file="SanityPageLayout.tsx" />
      <PageLayout preview={preview} {...toObjectId(page._id)} banner={page.showLayout ? undefined : <div />} header={page.showLayout ? undefined : <div />} footer={page.showLayout ? undefined : <div />} headerProps={{
      colorClassName: page.headerColorClassName
    }} data-sentry-element="PageLayout" data-sentry-source-file="SanityPageLayout.tsx">
        {page.sections?.map(renderSection)}
      </PageLayout>
    </PartnerProvider>;
};
const renderSection = (section, section_idx) => {
  const Component = getDocumentComponent(section);
  if (!Component) {
    if (isDev) {
      return <Section className="bg-orange" {...toFieldPath(`sections.${section_idx}`)}>
          <Container className="rounded-xl border-2 border-error-red py-20 text-center text-white">
            Could not find <p className="text-h1 text-white">{section._type}</p>
            do you have it defined in the componenetRegistry as
            SectionComponentName convention?
          </Container>
        </Section>;
    } else {
      throw new Error("Could not find Section Renderer for " + section._type);
    }
  }
  return <Component key={section._key} annotationPrefix={`sections.[${section_idx}]`} {...toObjectId(section._id)} {...section} data-sentry-element="Component" data-sentry-component="renderSection" data-sentry-source-file="SanityPageLayout.tsx" />;
};