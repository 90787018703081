import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import { getComponent as getActualComponent } from "../../components/componentRegistry";
import type { SanityDocument } from "./sanity.types";

export function getDocumentComponent(document: SanityDocument) {
  const type = getDocumentType(document);
  return getActualComponent(type);
}

export function getDocumentType(document: SanityDocument) {
  return upperFirst(camelCase(document._type));
}
export const stripSlash = (s: string) => s.replace(/^\/|\/$/g, "");

export const prepareSlug = (slug: string): string => {
  return `/${stripSlash(slug)}`;
};

export const sanitySlug = (slug: string): string => {
  const stripped = stripSlash(slug);
  return stripped === "" ? "/" : stripped;
};
