import clsx from "clsx";
import React from "react";
interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
  variant?: SectionVariant;
  bgClassName?: string;
  bottomClassName?: string;
}
export type SectionVariant = "short" | "short-normal" | "short-tall" | "normal" | "normal-tall" | "normal-short" | "tall" | "tall-short" | "tall-normal";
export const Section: React.FC<Props> = ({
  variant = "normal",
  className,
  bgClassName,
  bottomClassName,
  children,
  ...props
}) => {
  return <div {...props} className={clsx("relative", buildSectionClassNames(variant), className, bgClassName)} data-sentry-component="Section" data-sentry-source-file="Section.tsx">
      {bottomClassName && <div className={clsx("absolute bottom-0 w-full box-content", BottomOffset[variant], bottomClassName)} />}
      {children}
    </div>;
};
function buildSectionClassNames(variant: SectionVariant): string {
  const topSizeType = variant.split("-")[0];
  const bottomSizeType = variant.split("-")[1] || topSizeType;
  const topSizeIndex = SizeOrder.indexOf(topSizeType);
  const bottomSizeIndex = SizeOrder.indexOf(bottomSizeType);
  const sizeClass = `${TopClasses[topSizeIndex]} ${BottomClasses[bottomSizeIndex]}`;
  return sizeClass;
}
const SizeOrder = ["short", "normal", "tall"];
const TopClasses = ["pt-0", "pt-8 md:pt-12", "pt-16 md:pt-24"];
const BottomClasses = ["pb-0", "pb-8 md:pb-12", "pb-16 md:pb-24"];

/**
 * The bottom offset is the height of the section minus the padding
 */
const BottomOffset: Record<SectionVariant, string> = {
  short: "h-1/2",
  "short-normal": "h-[calc(50%+16px)] md:h-[calc(50%+24px)]",
  "short-tall": "h-[calc(50%+32px)] md:h-[calc(50%+48px)]",
  normal: "h-1/2",
  "normal-tall": "h-[calc(50%+16px)] md:h-[calc(50%+24px)]",
  "normal-short": "h-[calc(50%-16px)] md:h-[calc(50%-24px)]",
  tall: "h-1/2",
  "tall-short": "h-[calc(50%-32px)] md:h-[calc(50%-48px)]",
  "tall-normal": "h-[calc(50%-16px)] md:h-[calc(50%-24px)]"
};